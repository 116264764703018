<template>
    <div class="py-4 container-fluid">
        <div class=" row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
                        <h6 class="mb-0">Students</h6>
                        <div>
                            <small class="text-bold ">Sort By:</small>
                            <small @click="sortListByBatch" class="mx-1">Batch</small>
                            <small @click="sortListName" class="mx-1">Name</small>

                            <select name="" id=""
                                class="text-secondary px-3 py-2 rounded border border-2 font-weight-bold text-xs">
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="all">All</option>
                            </select>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Group</th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Contact</th>
                                        <th
                                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Action</th>
                                        <th
                                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Status</th>
                                        <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Employed</th> -->
                                        <!-- <th class="text-secondary opacity-7"></th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="student in students" :key="student.id">
                                        <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                    <!-- <img :src="'https://ui-avatars.com/api/?name=' + student.firstname + ' ' + student.lastname"
                                                        class="avatar avatar-sm me-3" alt="user1" /> -->
                                                    <img :src="student.profile ? 'https://softworica.com/storage/' + student.profile : 'https://ui-avatars.com/api/?name=' + student.firstname + ' ' + student.lastname"
                                                        class="avatar avatar-sm me-3" style="height: auto !important;"
                                                        alt="user1" />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="mb-0 text-sm" info-id="{{ data }}">{{ student.firstname }}
                                                        <span v-if="student.middlename != null">{{ student.middlename
                                                        }}</span>
                                                        {{ student.lastname }}
                                                    </h6>
                                                    <p class="text-xs text-secondary mb-0">{{ student.email }}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p class="text-xs font-weight-bold mb-0">{{ student.course_id ?
                                                student.course.name : 'NA' }}</p>
                                            <p class="text-xs text-secondary mb-0">{{ student.group_id ? student.group.name
                                                :
                                                'NA' }}</p>
                                        </td>
                                        <td>
                                            <p class="text-xs text-secondary mb-0">{{ student.batch ? student.batch.name :
                                                "NA" }}</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <router-link :to="{ name: 'ViewStudent', params: { id: student.id } }"
                                                class="text-secondary font-weight-bold text-xs">View</router-link>
                                            <!-- <span class="badge badge-sm bg-gradient-success">Online</span> -->
                                        </td>
                                        <td class="align-middle text-center">
                                            <span v-if="student.filled_status == 'completed'"
                                                class="badge badge-sm bg-gradient-primary"
                                                style="border-radius: 100% !important; aspect-ratio: 2/2; display: inline-flex; align-items: center !important; justify-content: center;"><i
                                                    class="bi bi-check m-0 fs-5 "></i></span>
                                        </td>
                                        <!-- <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold">23/04/18</span>
                </td>
                <td class="align-middle">
                    <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip"
                        data-original-title="Edit user">Edit</a>
                </td> -->
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 row">
            <div class="col-12">
            </div>
        </div>
    </div>
</template>
  
<script>
import axiosConfig from '../../baseaxios';
export default {
    components: {
    },
    data() {
        return {
            students: [],
            limit: 50,
            sorted: false,
        }
    },
    mounted() {
        var limit = 'all';
        var filter = 'all';
        axiosConfig.get(`back/students/list/${limit}/${filter}`).then(resp => {
            this.students = resp.data.students;
            console.log(resp)
        })
        console.log("Informations")
    },
    methods: {
        sortListByBatch() {
            // Use the Array.prototype.sort() method to sort the list based on the "propertyName" property
            if (this.sorted) {
                this.students.sort((a, b) => {
                    // Change 'propertyName' to the actual property you want to sort by
                    return a.batch.name.localeCompare(b.batch.name);
                });

            } else {
                this.students.sort((a, b) => {
                    // Change 'propertyName' to the actual property you want to sort by
                    return b.batch.name.localeCompare(a.batch.name);
                });
            }
            this.sorted = !this.sorted;
        },
        sortListName() {
            // Use the Array.prototype.sort() method to sort the list based on the "propertyName" property
            if (this.sorted) {
                this.students.sort((a, b) => {
                    // Change 'propertyName' to the actual property you want to sort by
                    return a.firstname.localeCompare(b.firstname);
                });

            } else {
                this.students.sort((a, b) => {
                    // Change 'propertyName' to the actual property you want to sort by
                    return b.firstname.localeCompare(a.firstname);
                });
            }
            this.sorted = !this.sorted;
        },
    }
};
</script>
  